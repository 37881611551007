<template>
  <div>
    <div class="div-form">
      <Messages />
      <div class="div-title">
        <span class="title-forgot">Redefinir Senha</span>
      </div>
      <b-row>
        <b-col sm="12">
          <InputPassword
            title="Nova Senha"
            field="password"
            :formName="formName"
            :required="true"
            :maxLength="50"
            v-model="forgot.password"
          >
            <div class="glyphicon">
              <i class="fas fa-lock"></i>
            </div>
          </InputPassword>
        </b-col>
        <b-col sm="12">
          <InputPassword
            title="Confirme Nova Senha"
            field="password"
            :formName="formName"
            :required="true"
            :maxLength="50"
            v-model="forgot.confirmPassword"
          >
            <div class="glyphicon">
              <i class="fas fa-lock"></i>
            </div>
          </InputPassword>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <div class="div-btn">
            <Button
              _key="save"
              type="success"
              title="Salvar"
              :disabled="disabled"
              classIcon="fa-solid fa-floppy-disk"
              size="medium"
              :clicked="save"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import InputPassword from "@nixweb/nixloc-ui/src/component/forms/InputPassword";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Messages from "@nixweb/nixloc-ui/src/component/shared/Messages.vue";

import ForgotPassword from "@/components/modules/adm/login/ForgotPassword.js";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "ResetPassword",
  components: { InputPassword, Button, Messages },
  data() {
    return {
      formName: "forgot",
      forgot: new ForgotPassword(),
      urlReset: "api/v1/adm/auth/reset-password",
      showSuccess: false,
    };
  },
  computed: {
    disabled() {
      if (
        this.forgot.password == this.forgot.confirmPassword &&
        this.forgot.password
      )
        return false;

      return true;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    save() {
      let obj = {
        codeResetPassword: this.$route.params.code,
        password: this.forgot.password,
      };
      let params = { url: this.urlReset, obj: obj };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$toasted.show("Salvo com sucesso", {
            type: "success",
          });
          this.$router.replace(this.$route.query.redirect || "/login");
        }
        this.removeLoading(["save"]);
      });
    },
  },
};
</script>
<style scoped>
.div-form {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.div-title {
  margin-bottom: 30px;
}

.div-btn {
  margin-top: 20px;
}

.title-forgot {
  font-size: 25px;
}

.title-rodape {
  font-size: 13px;
}

.icon-whatsapp {
  color: green;
}
</style>
