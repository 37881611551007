<template>
  <div style="overflow: hidden">
    <div class="div-logo text-center">
      <img src="https://nixloc.com.br/wp-content/themes/nix-loc/images/logo.svg" />
    </div>
    <br />
    <div class="principal">
      <Molded>
        <ResetPassword />
      </Molded>
    </div>
    <div class="text-center">
      <a href="https://www.instagram.com/nixloc.sistema/?hl=pt-br" target="_blank" class="fa fa-instagram"></a>
      <a href="https://www.facebook.com/nixloc.sistema" target="_blank" class="fa fa-facebook"></a>
      <a href="https://www.youtube.com/channel/UC2uW-eHwBwy_EYXj9ISayWQ" target="_blank" class="fa fa-youtube"></a>
      <a href="https://wa.me/551131362654" target="_blank" class="fa fa-whatsapp"></a>
    </div>
  </div>
</template>

<script>

import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import ResetPassword from '../../../components/modules/adm/login/ResetPassword.vue'

export default {
  name: "ResetPasswordView",
  components: { Molded, ResetPassword },
  data() {
    return {
      forgotPassword: false,
    };
  },
};
</script>

<style scoped>
.principal {
  background-color: white;
  max-width: 500px;
  margin: auto;
}

.div-logo {
  margin-top: 30px;
  padding: 18px;
}

.fa {
  padding: 20px;
  font-size: 25px;
  width: 45px;
  text-align: center;
  text-decoration: none;
  color: #c4c4c4;
}

.fa-facebook:hover {
  color: #0b86ee;
}

.fa-youtube:hover {
  color: #bb0000;
}

.fa-instagram:hover {
  color: #125688;
}

.fa-whatsapp:hover {
  color: green;
}
</style>
